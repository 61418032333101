import styled from "styled-components";

export const StyledWeltPlusBanner = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;

  background-color: ${(props) => props.theme.color.white};
  border-top-right-radius: ${(props) => props.theme.radius.s};

  &.absolute-bottom-left {
    position: absolute;
    padding: 8px 8px 0 1px;
    bottom: -4px;
    left: -1px;
  }
`;

export const StyledExclusiveBanner = styled.span`
  text-transform: uppercase;

  color: ${(props) => props.theme.color.text};
  line-height: ${(props) => props.theme.lineHeight.s};
  font-size: ${(props) => props.theme.fontSize.s};
  font-family: ${(props) => props.theme.fontFamily.regular};
  margin-left: ${(props) => props.theme.margin.s};
`;

import React from "react";
import SVG from "react-inlinesvg";

import { ConfigContext } from "@/factory/apps/App";
import { Icons } from "@/styles/styles";

import { StyledExclusiveBanner, StyledWeltPlusBanner } from "./styles";

type WeltPlusBannerProps = {
  className?: string;
  dataQa?: string;
};

const WeltPlusBanner: React.FC<WeltPlusBannerProps> = (
  props: WeltPlusBannerProps,
): JSX.Element => {
  const { iconSourceURL } = React.useContext(ConfigContext);

  return (
    <StyledWeltPlusBanner className={props.className} data-qa={props.dataQa}>
      <SVG src={`${iconSourceURL}${Icons.WeltPlus}.svg`} />
      <StyledExclusiveBanner>exklusiv</StyledExclusiveBanner>
    </StyledWeltPlusBanner>
  );
};

export default WeltPlusBanner;
